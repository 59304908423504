import { ApolloProvider } from "@apollo/client";
import { ReactKeycloakProvider } from "@react-keycloak-fork/web";
import { Dedupe } from "@sentry/integrations";
import { CaptureConsole } from "@sentry/integrations/esm/captureconsole";
import { RewriteFrames } from "@sentry/integrations/esm/rewriteframes";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { ConfigProvider, notification, Spin } from "antd";
import deDE from "antd/locale/de_DE";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import moment from "moment";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { applyMiddleware, createStore } from "redux";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import thunk from "redux-thunk";
import App from "./App";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { Permission } from "./generated/types";
import { initApolloClient } from "./initApolloClient";
import { rootReducer } from "./state";
import { AppApi } from "./state/app";
import { getEnvironment } from "./util/getEnvironment";
import { keycloak } from "./util/keycloak";

if (import.meta.env.SENTRY_DSN !== null) {
    const dist = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG?.dist;
    const release = import.meta.env.VITE_PLUGIN_SENTRY_CONFIG?.release;

    Sentry.init({
        dsn: import.meta.env.SENTRY_DSN,
        environment: getEnvironment(),

        integrations: [
            new Dedupe(),
            new BrowserTracing(),
            new RewriteFrames(),
            new CaptureConsole({ levels: ["error"] }),
        ],
        dist,
        release,

        tracesSampleRate: 1.0,

        autoSessionTracking: true,
    });
}

const root = document.createElement("div");
document.body.appendChild(root);

const store = createStore(rootReducer, applyMiddleware(thunk));
store.subscribe(() => {
    (window as any).state = store.getState();
});
const persistor = persistStore(store);

dayjs.locale("de");
dayjs.extend(relativeTime);
moment.locale("de");

const apolloClient = initApolloClient(keycloak);

createRoot(root).render(
    <ErrorBoundary key="root">
        <ReactKeycloakProvider
            authClient={keycloak}
            initOptions={{
                enableLogging: false,
                onLoad: "login-required",
                checkLoginIframe: false,
            }}
            onTokens={({ token }) => {
                if (token) {
                    store.dispatch(
                        AppApi.set(
                            "myUserGroups",
                            keycloak.tokenParsed?.["groups"]?.map((s: string) => s.replace("/", "")),
                        ),
                    );
                }
                const permissions = keycloak.tokenParsed?.resource_access?.["backend"]?.roles;
                if (permissions) {
                    store.dispatch(AppApi.set("myUserPermissions", permissions as Permission[]));
                }
                store.dispatch(
                    AppApi.set("myUserData", {
                        name: keycloak.idTokenParsed?.["name"] ?? "",
                        email: keycloak.idTokenParsed?.["email"] ?? "",
                        id: keycloak.idTokenParsed?.sub ?? "",
                    }),
                );
                if (keycloak.idTokenParsed) {
                    Sentry.setUser({
                        id: keycloak.idTokenParsed.sub ?? "",
                        email: keycloak.idTokenParsed["email"] ?? "",
                        username: keycloak.idTokenParsed["name"] ?? "",
                    });
                } else {
                    Sentry.setUser(null);
                }
            }}
            onEvent={(evt, err) => {
                switch (evt) {
                    case "onAuthLogout":
                        break;
                    case "onAuthRefreshSuccess":
                    case "onAuthSuccess":
                    case "onReady":
                        break;
                    case "onTokenExpired":
                        console.debug("kc token expired");
                        break;
                    case "onAuthError":
                    case "onAuthRefreshError":
                    case "onInitError":
                    default:
                        notification.error({
                            placement: "top",
                            key: "keycloak-error",
                            message: "Authentifizierungsfehler",
                            description: <i>{err?.error}</i>,
                            duration: 0,
                        });
                        console.error("kc error", err);
                        break;
                }
            }}
        >
            <ApolloProvider client={apolloClient}>
                <Provider store={store}>
                    <PersistGate loading={<Spin tip="Lade Einstellungen" />} persistor={persistor}>
                        <ConfigProvider
                            locale={deDE}
                            theme={{
                                token: {
                                    fontFamily: "PT Sans",
                                    colorPrimary: "#0097cc",
                                    colorLink: "#0097cc",
                                },
                            }}
                        >
                            <App />
                        </ConfigProvider>
                    </PersistGate>
                </Provider>
            </ApolloProvider>
        </ReactKeycloakProvider>
    </ErrorBoundary>,
);
