import * as Sentry from "@sentry/react";
import { Button } from "antd";
import { Content } from "antd/es/layout/layout";
import Result from "antd/es/result";
import { isRouteErrorResponse, useRouteError } from "react-router-dom";
import { useSetTitle } from "../hooks/useSetTitle";
import { showSentryReportDialog } from "../util/showSentryReportDialog";

export const RouterErrorView = ({ key }: { key: React.Key }) => {
    const error = useRouteError();
    const eventId = isRouteErrorResponse(error) && error.error ? Sentry.captureException(error.error) : undefined;

    useSetTitle("Fehler");

    return (
        <Content style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Result
                status="500"
                title="Ups, das ist schief gegangen."
                subTitle={
                    <>
                        {eventId ? (
                            <>
                                Wir wurden bereits automatisch darüber benachrichtigt. Bitte die Seite neu laden.
                                <br />
                                <i>Event-ID</i>: <code className="text-xs">{eventId}</code>
                            </>
                        ) : (
                            "Bitte die Seite neu laden."
                        )}
                        {isRouteErrorResponse(error) && error.statusText ? (
                            <>
                                <br />
                                <span style={{ maxWidth: "50vw" }}>
                                    <i>Fehler:</i> <code className="text-xs">{error.statusText}</code>
                                </span>
                            </>
                        ) : null}
                    </>
                }
                extra={
                    <>
                        <Button type="primary" onClick={() => location.reload()}>
                            Neu laden
                        </Button>
                        {eventId ? (
                            <Button
                                type="link"
                                onClick={() =>
                                    showSentryReportDialog({
                                        eventId: eventId,
                                    })
                                }
                            >
                                Weitere Informationen hinzufügen
                            </Button>
                        ) : null}
                    </>
                }
            />
        </Content>
    );
};
