import { useLayoutEffect } from "react";
import { ENV, getEnvironment } from "../util/getEnvironment";

export const useSetTitle = (title?: string) => {
    const environment = getEnvironment();

    useLayoutEffect(() => {
        let prefix = "";
        if (environment === ENV.dev) {
            prefix = `[DEV] EAP`;
        } else if (environment === ENV.stg) {
            prefix = `[STG] EAP`;
        } else {
            prefix = "EAP";
        }
        document.title = prefix + (title ? ` - ${title}` : "");
    }, [title]);
};
