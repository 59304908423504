import Keycloak from "keycloak-js";

export const keycloak = new Keycloak({
    clientId: import.meta.env.KEYCLOAK_CLIENT_ID,
    realm: import.meta.env.KEYCLOAK_REALM,
    url: import.meta.env.KEYCLOAK_URL,
});

export const getAuthToken = async (): Promise<string | null> =>
    new Promise((resolve, reject) =>
        keycloak
            .updateToken(10)
            .then(() => {
                if (!keycloak.token) {
                    console.error("kc token is null");
                    reject();
                }

                return resolve(keycloak.token ?? null);
            })
            .catch((err) => {
                console.warn("kc token update failed", err);
                reject();
            }),
    );
