import * as Sentry from "@sentry/react";
import { FallbackRender } from "@sentry/react";
import { Button, Layout } from "antd";
import { Content, Footer, Header } from "antd/es/layout/layout";
import Result from "antd/es/result";
import React from "react";
import { showSentryReportDialog } from "../util/showSentryReportDialog";

const FallbackComponent: FallbackRender = (errorData) => {
    return (
        <Layout style={{ minHeight: "100vh" }}>
            <Header />
            <Content style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Result
                    status="500"
                    title="Ups, das ist schief gegangen."
                    subTitle={
                        <>
                            Wir wurden bereits automatisch darüber benachrichtigt. Bitte die Seite neu laden.
                            <br />
                            <i>Event-ID</i>: <code className="text-xs">{errorData.eventId}</code>
                            <br />
                            {errorData.error.message !== undefined ? (
                                <>
                                    <div style={{ maxWidth: "50vw" }}>
                                        <i>Fehler:</i> <code className="text-xs">{errorData.error.message}</code>
                                    </div>
                                </>
                            ) : null}
                        </>
                    }
                    extra={
                        <>
                            <Button type="primary" onClick={() => location.reload()}>
                                Neu laden
                            </Button>
                            <Button
                                type="link"
                                onClick={() =>
                                    showSentryReportDialog({
                                        eventId: errorData.eventId,
                                    })
                                }
                            >
                                Weitere Informationen hinzufügen
                            </Button>
                        </>
                    }
                />
            </Content>
            <Footer />
        </Layout>
    );
};

export const ErrorBoundary = ({ children }: { children: React.ReactNode }) => {
    return <Sentry.ErrorBoundary fallback={FallbackComponent}>{children}</Sentry.ErrorBoundary>;
};
