import { setAutoFreeze } from "immer";
import { combineReducers } from "redux";
import { ApolloWsReduxReducer, ApolloWsState } from "./apollo";
import { AppReduxReducer, AppState } from "./app";
import { FiltersReduxReducer, FiltersState } from "./filters";
import { ModalsReduxReducer, ModalsState } from "./modals";
import { TableReduxReducer, TableState } from "./table";
import { WbsReduxReducer, WbsState } from "./wbs";

setAutoFreeze(false);

export const rootReducer = combineReducers({
    app: AppReduxReducer,
    filters: FiltersReduxReducer,
    modal: ModalsReduxReducer,
    table: TableReduxReducer,
    wbs: WbsReduxReducer,
    apollo: ApolloWsReduxReducer,
});

// tslint:disable-next-line: no-empty-interface
export interface State {
    app: AppState;
    filters: FiltersState;
    modal: ModalsState;
    table: TableState;
    wbs: WbsState;
    apollo: ApolloWsState;
}

export type GetState = () => State;
