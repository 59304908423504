export enum ENV {
    dev = "development",
    stg = "staging",
    prd = "production",
}

export const getEnvironment = (): ENV => {
    const url = window.location.origin;

    if (url.includes("localhost") || url.includes("feinarbyte-dev.de")) {
        return ENV.dev;
    } else if (url.includes("staging") || url.includes("stg")) {
        return ENV.stg;
    } else {
        return ENV.prd;
    }
};
