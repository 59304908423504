import "@ant-design/pro-layout/es/components/PageHeader/style/index";
import { useApolloClient } from "@apollo/client";
import { AtomContextProvider } from "@feinarbyte/atom-client";
import { useKeycloak } from "@react-keycloak-fork/web";
import * as Sentry from "@sentry/react";
import { Button, Layout, notification, Result } from "antd";
import "antd/es/alert/style/index";
import "antd/es/badge/style/index";
import "antd/es/breadcrumb/style/index";
import "antd/es/button/style/index";
import "antd/es/col/style/index";
import "antd/es/collapse/style/index";
import "antd/es/date-picker/style/index";
import "antd/es/divider/style/index";
import "antd/es/dropdown/style/index";
import "antd/es/empty/style/index";
import "antd/es/form/style/index";
import "antd/es/input-number/style/index";
import "antd/es/input/style/index";
import { Content } from "antd/es/layout/layout";
import "antd/es/layout/style/index";
import "antd/es/list/style/index";
import "antd/es/message/style/index";
import "antd/es/modal/style/index";
import "antd/es/popconfirm/style/index";
import "antd/es/popover/style/index";
import "antd/es/progress/style/index";
import "antd/es/radio/style/index";
import "antd/es/row/style/index";
import "antd/es/select/style/index";
import "antd/es/switch/style/index";
import "antd/es/table/style/index";
import "antd/es/tag/style/index";
import "antd/es/tooltip/style/index";
import "antd/es/tree/style/index";
import "dayjs/locale/de";
import { lazy, Suspense, useCallback } from "react";
import { useSelector, useStore } from "react-redux";
import { createBrowserRouter, createRoutesFromElements, Outlet, Route, RouterProvider } from "react-router-dom";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { Header } from "./components/Header";
import { NotFoundView } from "./components/NotFoundView";
import { ProjectTreeLoader } from "./components/ProjectTreeLoader";
import { SplashScreen } from "./components/reusable/SplashScreen";
import { RouterErrorView } from "./components/RouterErrorView";
import { Footer } from "./Footer";
import { atomPathes } from "./generated/atompathes";
import { useEapContext } from "./hooks/useEapContext";
import { useSetTitle } from "./hooks/useSetTitle";
import { State } from "./state";
import "./style/app.scss";
import { getAuthToken } from "./util/keycloak";
import { showSentryReportDialog } from "./util/showSentryReportDialog";

// preloaded components
const WorkpackageListViewImport = import("./components/WorkpackageListView");
const WorkpackageListView = lazy(() => WorkpackageListViewImport);
const WorkpackageViewImport = import("./components/WorkpackageView");
const WorkpackageView = lazy(() => WorkpackageViewImport);

// lazily loaded components
const WBSView = lazy(() => import("./components/WBS/WBSView"));
const WBSTableView = lazy(() => import("./components/WBS/WBSTableView"));
const ProjectActivityView = lazy(() => import("./components/ProjectActivityView"));
const TemplateEditor = lazy(() => import("./components/TemplateEditor"));
const GanttView = lazy(() => import("./components/GantView"));

export const App = () => {
    const { initialized } = useKeycloak();
    const apolloClient = useApolloClient();

    const userGroups = useSelector((state: State) => state.app.myUserGroups);

    const store = useStore();
    const onError = useCallback(
        (e: Error | string) => {
            const eventId = Sentry.captureException(e);
            notification.error({
                placement: "top",
                key: eventId,
                message: "Ups, das ist schief gegangen",
                description: (
                    <>
                        Wir wurden automatisch benachrichtigt und kümmern uns darum.
                        <br />
                        <small>
                            <i>Fehler:</i> <code>{e instanceof Error ? e.message : e}</code>
                            <br />
                            <i>Event-ID:</i> <code>{eventId}</code>
                        </small>
                    </>
                ),
                duration: null,
                btn: (
                    <Button
                        type="primary"
                        onClick={() => {
                            notification.destroy(eventId);
                            showSentryReportDialog({
                                eventId,
                            });
                        }}
                    >
                        Weitere Informationen senden
                    </Button>
                ),
            });
        },
        [store],
    );

    useSetTitle("");

    return (
        <AtomContextProvider
            apolloClient={apolloClient}
            atomPathes={atomPathes}
            getContextHook={useEapContext}
            onError={onError}
            getAuthToken={getAuthToken}
        >
            <RouterProvider
                router={createBrowserRouter(
                    createRoutesFromElements(
                        !initialized ? (
                            <Route
                                path="*"
                                element={
                                    <ErrorBoundary key="splash">
                                        <SplashScreen />
                                    </ErrorBoundary>
                                }
                                errorElement={<RouterErrorView key="splash" />}
                            />
                        ) : (
                            <Route
                                element={
                                    <ErrorBoundary key="outlet">
                                        <Layout style={{ minHeight: "100vh" }}>
                                            <Header />
                                            <Outlet />
                                            <Footer />
                                        </Layout>
                                    </ErrorBoundary>
                                }
                                errorElement={<RouterErrorView key="outlet" />}
                            >
                                {!userGroups || userGroups.length === 0 ? (
                                    <Route
                                        path="*"
                                        element={
                                            <ErrorBoundary key="403">
                                                <Content>
                                                    <Result
                                                        status="403"
                                                        title="Zugriff verweigert"
                                                        subTitle="Sie haben aktuell keinen Zugriff auf das AP-Tool."
                                                        extra={
                                                            <Button
                                                                type="primary"
                                                                href={`mailto:info@program51.de?subject=Zugang AP-Tool&body=Hallo,%0D%0A%0D%0Aich bitte um Zugangsberechtigung für das AP-Tool.%0D%0AMein Nutzername lautet: `}
                                                            >
                                                                Zugriff anfragen
                                                            </Button>
                                                        }
                                                    />
                                                </Content>
                                            </ErrorBoundary>
                                        }
                                        errorElement={<RouterErrorView key="403" />}
                                    />
                                ) : (
                                    <>
                                        <Route
                                            path="/template"
                                            element={
                                                <ErrorBoundary key="template">
                                                    <Suspense fallback={<SplashScreen />}>
                                                        <TemplateEditor />
                                                    </Suspense>
                                                </ErrorBoundary>
                                            }
                                            errorElement={<RouterErrorView key="template" />}
                                        />
                                        <Route
                                            path="/template/:templateName"
                                            element={
                                                <ErrorBoundary key="/template/:templateName">
                                                    <Suspense fallback={<SplashScreen />}>
                                                        <TemplateEditor />
                                                    </Suspense>
                                                </ErrorBoundary>
                                            }
                                            errorElement={<RouterErrorView key="/template/:templateName" />}
                                        />
                                        <Route
                                            element={
                                                <ErrorBoundary key="ProjectTreeLoader">
                                                    <ProjectTreeLoader>
                                                        <Outlet />
                                                    </ProjectTreeLoader>
                                                </ErrorBoundary>
                                            }
                                            errorElement={<RouterErrorView key="ProjectTreeLoader" />}
                                        >
                                            <Route
                                                path="/wbs/:subCode"
                                                element={
                                                    <ErrorBoundary key="/wbs/:subCode">
                                                        <Suspense fallback={<SplashScreen />}>
                                                            <WBSView />
                                                        </Suspense>
                                                    </ErrorBoundary>
                                                }
                                                errorElement={<RouterErrorView key="/wbs/:subCode" />}
                                            />
                                            <Route
                                                path="/wbs"
                                                element={
                                                    <ErrorBoundary key="/wbs">
                                                        <Suspense fallback={<SplashScreen />}>
                                                            <WBSView />
                                                        </Suspense>
                                                    </ErrorBoundary>
                                                }
                                                errorElement={<RouterErrorView key="/wbs" />}
                                            />
                                            <Route
                                                path="/wbs/table"
                                                element={
                                                    <ErrorBoundary key="/wbs/table">
                                                        <Suspense fallback={<SplashScreen />}>
                                                            <WBSTableView />
                                                        </Suspense>
                                                    </ErrorBoundary>
                                                }
                                                errorElement={<RouterErrorView key="/wbs/table" />}
                                            />
                                            <Route
                                                path="/activity"
                                                element={
                                                    <ErrorBoundary key="/activity">
                                                        <Suspense fallback={<SplashScreen />}>
                                                            <ProjectActivityView />
                                                        </Suspense>
                                                    </ErrorBoundary>
                                                }
                                                errorElement={<RouterErrorView key="/activity" />}
                                            />
                                            <Route
                                                path="/p/:code"
                                                element={
                                                    <ErrorBoundary key="/p/:code">
                                                        <Suspense fallback={<SplashScreen />}>
                                                            <WorkpackageView />
                                                        </Suspense>
                                                    </ErrorBoundary>
                                                }
                                                errorElement={<RouterErrorView key="/p/:code" />}
                                            />
                                            <Route
                                                path="/"
                                                element={
                                                    <ErrorBoundary key="/">
                                                        <Suspense fallback={<SplashScreen />}>
                                                            <WorkpackageListView />
                                                        </Suspense>
                                                    </ErrorBoundary>
                                                }
                                                errorElement={<RouterErrorView key="/" />}
                                            />
                                            <Route
                                                path="/gantt"
                                                element={
                                                    <ErrorBoundary key="/gantt">
                                                        <Suspense fallback={<SplashScreen />}>
                                                            <GanttView />
                                                        </Suspense>
                                                    </ErrorBoundary>
                                                }
                                                errorElement={<RouterErrorView key="/gantt" />}
                                            />
                                            <Route
                                                path="*"
                                                element={
                                                    <ErrorBoundary key="NotFound">
                                                        <Suspense fallback={<SplashScreen />}>
                                                            <NotFoundView />
                                                        </Suspense>
                                                    </ErrorBoundary>
                                                }
                                                errorElement={<RouterErrorView key="NotFound" />}
                                            />
                                        </Route>
                                    </>
                                )}
                            </Route>
                        ),
                    ),
                )}
            />
        </AtomContextProvider>
    );
};

export default App;
