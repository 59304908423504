import {
    faChevronDown,
    faFile,
    faPowerOff,
    faQuestionCircle as faQuestionCircleSolid,
    faUser,
    faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useKeycloak } from "@react-keycloak-fork/web";
import Button from "antd/es/button";
import Divider from "antd/es/divider";
import Dropdown from "antd/es/dropdown";
import { MenuProps } from "antd/es/menu";
import { CSSProperties, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { version as frontendVersion } from "../../package.json";
import { Permission } from "../generated/types";
import { useHasPermission } from "../hooks/useHasPermission";
import { getBackendUrl } from "../service_urls";
import { State } from "../state";
import { AsyncButton } from "./reusable/AsyncButton";

interface OwnProps {
    style?: CSSProperties;
}
export const UserButton = ({ style }: OwnProps) => {
    const { initialized, keycloak } = useKeycloak();

    const myUserGroups = useSelector((state: State) => state.app.myUserGroups);
    const myUserData = useSelector((state: State) => state.app.myUserData);

    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [backendVersion, setBackendVersion] = useState<string>("loading..");

    const { backendBaseUrl } = getBackendUrl();

    const hasPermission = useHasPermission();

    useEffect(() => {
        void (async () => {
            setBackendVersion(
                await fetch(`${backendBaseUrl}/version`)
                    .then(async (res) => {
                        const json = await res.json();

                        return json["version"];
                    })
                    .catch((e) => "unknown"),
            );
        })();
    }, [backendBaseUrl]);

    const items: MenuProps["items"] = [
        {
            key: "1",
            label: "Benutzerinformationen",
            children: [
                {
                    key: "1-1",
                    disabled: true,
                    className: "info",
                    label: (
                        <>
                            E-Mail: <code className="text-xs">{myUserData?.email ?? "Unbekannt"}</code>
                            <br />
                            Gruppen:{" "}
                            <code className="text-xs">
                                {!myUserGroups || myUserGroups.length === 0
                                    ? "keine"
                                    : myUserGroups.map((role, idx) => {
                                          if (idx < myUserGroups.length - 1) {
                                              return `${role.charAt(0)}${role.slice(1).toLowerCase()}, `;
                                          } else {
                                              return `${role.charAt(0)}${role.slice(1).toLowerCase()}`;
                                          }
                                      })}
                            </code>
                        </>
                    ),
                },
            ],
        },
        {
            key: "2",
            label: "Anwendungsinformationen",
            children: [
                {
                    key: "2-1",
                    disabled: true,
                    className: "info",
                    label: (
                        <>
                            <b>Versionen</b>
                            <Divider style={{ marginTop: 3, marginBottom: 3 }} />
                            Frontend: <code className="text-xs">v{frontendVersion}</code>
                            <br />
                            Backend: <code className="text-xs">{backendVersion}</code>
                        </>
                    ),
                },
            ],
        },
        {
            key: "3",
            label: (
                <>
                    <a href="mailto:info@program51.de">
                        <FontAwesomeIcon icon={faQuestionCircleSolid} /> Kontakt
                    </a>
                </>
            ),
        },
        {
            key: "templates",
            label: (
                <>
                    <Link to="/template">
                        <FontAwesomeIcon icon={faFile} style={{ marginRight: "5px" }} />
                        Berichtsvorlagen
                    </Link>
                </>
            ),
        },
        hasPermission(Permission.RoleUserManager)
            ? {
                  key: "4",
                  label: (
                      <>
                          <a
                              href={`${import.meta.env.KEYCLOAK_URL}/admin/${import.meta.env.KEYCLOAK_REALM}/console/`}
                              target="_blank"
                              rel="noreferrer"
                          >
                              <FontAwesomeIcon icon={faUsers} style={{ marginRight: "5px" }} />
                              Nutzerverwaltung
                          </a>
                      </>
                  ),
              }
            : undefined,
        {
            type: "divider",
        },
        {
            key: "5",
            label: (
                <>
                    <AsyncButton
                        block
                        type="primary"
                        danger
                        onClick={() => keycloak.logout({ redirectUri: window.location.origin })}
                    >
                        <FontAwesomeIcon icon={faPowerOff} style={{ marginRight: "5px" }} />
                        Ausloggen
                    </AsyncButton>
                </>
            ),
        },
    ].filter(Boolean) as MenuProps["items"];

    return initialized && keycloak.authenticated ? (
        <>
            <Dropdown placement="bottomRight" menu={{ items }} onOpenChange={(open) => setDropdownVisible(open)}>
                <Button type="primary" style={{ ...style, padding: "0px" }}>
                    <div style={{ height: "inherit", display: "flex", alignItems: "center" }}>
                        <FontAwesomeIcon icon={faUser} style={{ marginRight: "7px", marginLeft: "10px" }} />
                        {(myUserData?.name.length ?? 0) > 0 ? myUserData?.name : "Kein Name"}
                        <Divider
                            type="vertical"
                            style={{
                                height: "inherit",
                                borderColor: "rgba(255,255,255,.6)",
                                marginLeft: "10px",
                                marginRight: "0px",
                            }}
                        />
                        <FontAwesomeIcon
                            rotation={dropdownVisible ? 180 : undefined}
                            icon={faChevronDown}
                            style={{
                                marginLeft: "10px",
                                marginRight: "10px",
                                fontSize: ".65rem",
                                transition: "transform .1s",
                            }}
                        />
                    </div>
                </Button>
            </Dropdown>
        </>
    ) : (
        <AsyncButton type="primary" style={{ ...style }} onClick={() => keycloak.login()}>
            Einloggen
        </AsyncButton>
    );
};
