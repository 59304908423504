import { FormInstance } from "antd/es/form/Form";
import Modal, { ModalProps } from "antd/es/modal";
import * as React from "react";
import { useState } from "react";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { useSelector } from "react-redux";
import { State } from "state";
import { classNames2 } from "../../classNames2";
import { ModalsApi } from "../../state/modals";
import { useThunkDispatch } from "../../useThunkDispatch";

export interface ReduxModalProps extends Omit<ModalProps, "onOk" | "onCancel" | "modalRender"> {
    id: string;
    dispatcher?: () => any;
    onOk?: (e: React.MouseEvent<HTMLElement>) => void | Promise<void>;
    children: React.ReactNode;
    form?: FormInstance;
    forceVisible?: boolean;
    onCancel?: () => void;
    onSubmit?: () => void;
    draggable?: boolean;
}

export const ReduxModal = ({
    id,
    dispatcher,
    onOk,
    children,
    form,
    forceVisible,
    onCancel,
    onSubmit,
    okButtonProps,
    title,
    draggable = false,
    ...props
}: ReduxModalProps) => {
    const dispatch = useThunkDispatch();
    const visible = useSelector((state: State) => state.modal[id]);
    const [disabled, setDisabled] = useState(false);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const draggleRef = React.useRef<HTMLDivElement>(null);

    const [isHandlingClick, setHandlingClick] = useState<boolean>(false);
    const [errorAnimation, setErrorAnimation] = useState(false);

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    return (
        <Modal
            open={visible || forceVisible}
            cancelText="Abbrechen"
            onCancel={() => {
                onCancel?.();
                return dispatch(ModalsApi.hide(id));
            }}
            okButtonProps={{
                ...okButtonProps,
                loading: isHandlingClick,
                className: classNames2(() => ({ "async-button-animated": errorAnimation }), "async-button"),
                onAnimationEnd: () => setErrorAnimation(false),
            }}
            onOk={async (e) => {
                onSubmit?.();
                form?.submit();

                if (form === undefined || form.getFieldsError().length === 0) {
                    try {
                        setHandlingClick(true);
                        if (dispatcher) {
                            await dispatch(dispatcher());
                        } else if (onOk) {
                            await onOk(e);
                        }
                        setHandlingClick(false);
                        dispatch(ModalsApi.hide(id));
                    } catch (e) {
                        setHandlingClick(false);
                        setErrorAnimation(true);
                        throw e;
                    }
                }
            }}
            title={
                draggable ? (
                    <div
                        style={{
                            width: "100%",
                            cursor: "move",
                        }}
                        onMouseOver={() => {
                            if (disabled) {
                                setDisabled(false);
                            }
                        }}
                        onMouseOut={() => {
                            setDisabled(true);
                        }}
                        // fix eslintjsx-a11y/mouse-events-have-key-events
                        // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
                        onFocus={() => {}}
                        onBlur={() => {}}
                        // end
                    >
                        {title}
                    </div>
                ) : (
                    title
                )
            }
            modalRender={
                draggable
                    ? (modal) => (
                          <Draggable
                              disabled={disabled}
                              bounds={bounds}
                              onStart={(event, uiData) => onStart(event, uiData)}
                          >
                              <div ref={draggleRef}>{modal}</div>
                          </Draggable>
                      )
                    : undefined
            }
            {...props}
        >
            {children}
        </Modal>
    );
};
