import * as Sentry from "@sentry/react";
import { ReportDialogOptions } from "@sentry/react";
import { State } from "../state";

export const showSentryReportDialog = (
    options: Omit<ReportDialogOptions, "lang" | "user" | "title" | "subtitle" | "subtitle2">,
) => {
    const myUserData = (globalThis.state as State).app.myUserData; // hacky, but useSelector may not be available here

    Sentry.showReportDialog({
        ...options,
        lang: "de",
        user: {
            email: myUserData?.email,
            name: myUserData?.name,
        },
        title: "Ups, das ist schief gegangen.",
        subtitle:
            "Wir wurden automatisch benachrichtigt und kümmern uns darum. Bitte beschreibe unten kurz, was du gerade gemacht hast.",
        subtitle2: "Alle Daten werden auschließlich lokal durch uns verarbeitet.",
    });
};
