import { ModalsApi } from "../../state/modals";
import { useThunkDispatch } from "../../useThunkDispatch";
import { ReduxModal } from "./ReduxModal";

export const DataProtectionDeclarationModalId = "dataProtectionDeclaration";
export const DataProtectionDeclarationModal = () => {
    const dispatch = useThunkDispatch();
    return (
        <ReduxModal
            id={DataProtectionDeclarationModalId}
            title="Datenschutzerklärung / Bildrechte"
            dispatcher={() => dispatch(ModalsApi.hide(DataProtectionDeclarationModalId))}
        >
            <h1>Datenschutzerklärung</h1>
            <i>Stand: 26.10.21 (1-0)</i>
            <br />
            <br />
            <h2>1. Zweck der Datenerhebung und Verantwortlicher</h2>
            <p>
                Die program51 GmbH („Verantwortlicher“) erhebt über diese Plattform personenbezogene Daten von
                Projektbeteiligten („Projektbeteiligte“) im Rahmen der Erfüllung eines Vertrages (Art. 6 Abs. 1 lit. b)
                DSGVO). Sofern im Einzelfall kein Vertragsverhältnis besteht, erfolgt die Verarbeitung aufgrund einer
                Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die Verarbeitung geschieht in jedem Fall zum Zwecke der
                Unterstützung eines Projektträgers bei der Planung und Durchführung eines Projekts.
            </p>
            <h2>2. Ansprechpartner</h2>
            <p>
                Ansprechpartner der program51 GmbH für alle Fragen zum Datenschutz ist:
                <br />
                Harald Lohrenscheit, hl@program51.de, +49 174 9711100
            </p>
            <h2>3. Kategorien von Daten</h2>
            <p>
                Zur Anbahnung und ggf. Durchführung des Vertrages mit dem Projektträger erhebt der Verantwortliche von
                den Projektbeteiligten Namen, E-Mail-Adresse, Telefonnummern und Funktion im Projekt.
            </p>
            <h2>4. Datenverarbeitung</h2>
            <p>
                Die Daten werden von dem Verantwortlichen gespeichert, genutzt und verarbeitet. Eine Weitergabe erfolgt
                an Projektbeteiligte, den Projektträger und Dritte wie Plattformbetreiber oder Genehmigungsbehörden.
                Eine Weitergabe der Daten zu anderen Zwecken als aufgeführt findet nicht statt.
            </p>
            <h2>5. Übermittlung personenbezogener Daten in Drittländer</h2>
            <p>Eine Übermittlung personenbezogener Daten in ein Drittland ist nicht beabsichtigt.</p>
            <h2>6. Speicherdauer</h2>
            <p>
                Personenbezogene Daten werden gespeichert, solange ihre Verarbeitung, für die unter Ziffer 1
                beschriebenen Zwecke erforderlich ist, soweit nicht gesetzliche Bestimmungen eine längere Speicherdauer
                vorschreiben.
            </p>
            <h2>7. Rechte der Betroffenen bzw. Projektbeteiligten</h2>
            <p>
                Sie haben das Recht, eine Auskunft über Ihre personenbezogenen Daten zu verlangen, die durch oder im
                Namen von Verantwortlichen verarbeitet werden. Sie haben das Recht, Ihre Daten gegebenenfalls zu
                berichtigen, zu löschen oder den Zugang zu ihnen einzuschränken. Sie haben das Recht, Ihre
                personenbezogenen Daten, die Sie uns bereitgestellt haben, in einem strukturierten, gängigen und
                maschinenlesbaren Format zu erhalten oder die Übermittlung an einen anderen Verantwortlichen zu
                verlangen. Sie haben auch das Recht, der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen
                (falls zutreffend). Sie können dieses Recht ausüben, indem Sie sich an den Ansprechpartner des
                Auftragnehmers für Datenschutz Fragen wenden (siehe Kontaktdaten unter Ziffer 2). Darüber hinaus besteht
                ein Beschwerderecht bei einer Datenschutzaufsichtsbehörde. Das Beschwerderecht besteht unbeschadet eines
                anderweitigen verwaltungsrechtlichen oder gerichtlichen Rechtsbehelfs.
            </p>
            <h2>8. Recht auf Widerruf einer Einwilligung</h2>
            <p>
                Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft bei dem Ansprechpartner des
                Verantwortlichen (siehe Ziffer 2) widerrufen.
            </p>
            <h1>Bildrechte</h1>
            <h2>decision.svg (Business choice decision Icon)</h2>
            &copy; MD BADSHA MEAH (
            <a href="https://creativecommons.org/licenses/by/4.0/" target="_blank" rel="noreferrer noopener">
                CC BY 4.0
            </a>
            )
        </ReduxModal>
    );
};
