import { useParams } from "react-router-dom";
import { EapContext } from "../generated/types";

export type EapContextValues = { [key in EapContext]?: any };

export function useEapContext(): EapContextValues {
    const { code, templateName } = useParams<{ code?: string; templateName?: string }>();
    return {
        [EapContext.Code]: code,
        [EapContext.Version]: "latest",
        [EapContext.TemplateName]: templateName,
    };
}
