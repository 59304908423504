import { Layout, Skeleton, Spin } from "antd";
import { Content } from "antd/es/layout/layout";
import { Footer } from "../../Footer";
import "../../style/splashScreen.scss";
import { Header } from "../Header";

export const SplashScreen = () => {
    return (
        <Spin>
            <Layout style={{ minHeight: "100vh" }}>
                <Header />
                <Content>
                    <Skeleton active />
                </Content>
                <Footer />
            </Layout>
        </Spin>
    );
};
