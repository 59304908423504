import { Reducer } from "@feinarbyte/reduxhelper";
import { SortOrder } from "antd/es/table/interface";

export interface TableSettings {
    sortField?: string;
    sortOrder?: SortOrder;
    paginationSize?: number;
    paginationIndex?: number;
}

export interface TableState {
    [tableName: string]: TableSettings;
}

export const defaultTableState: TableState = {};

class TableReducer extends Reducer<TableState> {
    constructor() {
        super(
            defaultTableState,
            false, // we may want to set this to true, but not for "paginationIndex"
        );
    }
    public changeTableSort(tableId: string, sortField?: string, sortOrder?: SortOrder) {
        this.state[tableId] = { ...this.state[tableId], sortField, sortOrder };
    }
    public changeTablePagination(tableId: string, paginationSize?: number, paginationIndex?: number) {
        this.state[tableId] = { ...this.state[tableId], paginationSize, paginationIndex };
    }
}

const TableReducerInstance = new TableReducer();
export const TableApi = TableReducerInstance.getApi();
export const TableReduxReducer = TableReducerInstance.getReduxReducer();
