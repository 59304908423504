import { Reducer } from "@feinarbyte/reduxhelper";
import { IActivity, ICategory, IWorkpackage, Permission } from "../generated/types";

interface UserData {
    name: string;
    email: string;
    id: string;
}

export const VERSION_DRAFT = "entwurf";

export interface AppState {
    activityDraft: IActivity | null;
    packageDraft: Partial<IWorkpackage> | null;
    categoryDraft: Partial<ICategory> | null;
    expanded: string[];
    favorites: { [code: string]: true };
    myUserGroups?: string[];
    myUserPermissions?: Permission[];
    myUserData?: UserData;
    activeVersion?: string;
    versionToCompare?: string;

    // UI
    siderIsDragging: boolean;
    siderHidden: boolean;
    siderWidth: number;
}

export const defaultAppState: AppState = {
    activityDraft: null,
    packageDraft: null,
    categoryDraft: null,
    expanded: ["all"],
    myUserPermissions: [],
    favorites: {},

    // UI
    siderIsDragging: false,
    siderHidden: true,
    siderWidth: 500,
};

class AppReducer extends Reducer<AppState> {
    constructor() {
        super(defaultAppState, true, ["favorites", "expanded", "siderWidth"]);
    }

    public updateCategoryDraft(patch: Partial<ICategory>) {
        this.state.categoryDraft = { ...this.state.categoryDraft!, ...patch };
    }

    public updatePackageDraft(patch: Partial<IWorkpackage>) {
        this.state.packageDraft = { ...this.state.packageDraft!, ...patch };
    }

    public updateActivityDraft(patch: Partial<IActivity>) {
        this.state.activityDraft = { ...this.state.activityDraft!, ...patch };
    }

    public swapVersionToCompare() {
        if (!this.state.versionToCompare) {
            return;
        }

        const tmp = this.state.activeVersion;
        this.state.activeVersion = this.state.versionToCompare;
        this.state.versionToCompare = tmp;
    }

    public toggleFavorite(code: string) {
        if (this.state.favorites[code]) {
            delete this.state.favorites[code];
        } else {
            this.state.favorites[code] = true;
        }
    }
}

const AppReducerInstance = new AppReducer();
export const AppApi = AppReducerInstance.getApi();
export const AppReduxReducer = AppReducerInstance.getReduxReducer();
