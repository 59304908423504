type Credentials = "include" | "same-origin";

export function getBackendUrl(): {
    backendBaseUrl: string;
    graphQlUrl: string;
    graphQlSubscriptionUrl: string;
    credentials: Credentials;
} {
    const customHost = localStorage.getItem("CUSTOM_HOST");

    let backendBaseUrl: string;
    let secure = true;
    let credentials: Credentials = "include";

    if (location.origin === "https://eap.program51.de") {
        // production
        backendBaseUrl = "api.eap.program51.de";
    } else if (location.origin === "https://stg.eap.program51.de") {
        // staging
        backendBaseUrl = "api.stg.eap.program51.de";
    } else if (location.origin === "https://ap51.feinarbyte.de") {
        // preview
        backendBaseUrl = "api.ap51.feinarbyte.de";
    } else if (location.origin === "https://v3.eap.program51.de") {
        backendBaseUrl = "api.v3.eap.program51.de";
    } else if (location.origin === "https://ap51demo.program51.de") {
        backendBaseUrl = "api.ap51demo.program51.de";
    } else {
        // local dev setup
        secure = location.protocol === "https:";
        backendBaseUrl = `${location.host}/backend`;
        credentials = "same-origin";
    }

    return {
        backendBaseUrl: `${secure ? "https" : "http"}://${customHost ?? backendBaseUrl}`,
        graphQlUrl: `${secure ? "https" : "http"}://${customHost ?? backendBaseUrl}/graphql`,
        graphQlSubscriptionUrl: `${secure ? "wss" : "ws"}://${customHost ?? backendBaseUrl}/graphql`,
        credentials: credentials,
    };
}
