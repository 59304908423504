import { faIdCard } from "@fortawesome/free-regular-svg-icons";
import { faUserShield } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "antd";
import { Footer as AntFooter } from "antd/es/layout/layout";
import { useSelector } from "react-redux";
import {
    DataProtectionDeclarationModal,
    DataProtectionDeclarationModalId,
} from "./components/modals/DataProtectionDeclarationModal";
import { ImprintModal, ImprintModalId } from "./components/modals/ImprintModal";
import { State } from "./state";
import { ModalsApi } from "./state/modals";
import { useThunkDispatch } from "./useThunkDispatch";

export const Footer = () => {
    const dispatch = useThunkDispatch();
    const siderWidth = useSelector((state: State) => state.app.siderWidth);
    const siderHidden = useSelector((state: State) => state.app.siderHidden);

    return (
        <>
            <DataProtectionDeclarationModal />
            <ImprintModal />
            <AntFooter
                className="footer"
                style={{
                    textAlign: "center",
                    // willChange: "margin-left",
                    // marginLeft: siderHidden ? "unset" : `${siderWidth}px`,
                    padding: "10px 50px",
                    paddingLeft: siderHidden ? undefined : `${siderWidth}px`,
                }}
            >
                <Button type="link" onClick={() => dispatch(ModalsApi.show(DataProtectionDeclarationModalId))}>
                    <FontAwesomeIcon icon={faUserShield} /> Datenschutzerklärung
                </Button>
                <Button type="link" onClick={() => dispatch(ModalsApi.show(ImprintModalId))}>
                    <FontAwesomeIcon icon={faIdCard} /> Impressum
                </Button>
            </AntFooter>
        </>
    );
};
