import { Operation } from "fast-json-patch";

export enum Signal {
    RED = "RED",
    YELLOW = "YELLOW",
    GREEN = "GREEN",
}
export enum Permission {
    CreateActivities = "create_activities",
    CreateCategories = "create_categories",
    CreateWorkpackages = "create_workpackages",
    CreateVersion = "create_version",
    CreateDraft = "create_draft",
    CreateTemplate = "create_template",
    DeleteActivities = "delete_activities",
    DeleteWorkpackages = "delete_workpackages",
    FreezeWorkpackages = "freeze_workpackages",
    DeleteCategories = "delete_categories",
    DeleteTemplate = "delete_template",
    DeleteDraft = "delete_draft",
    ReadActivities = "read_activities",
    ReadCategories = "read_categories",
    ReadUsers = "read_users",
    ReadWorkpackages = "read_workpackages",
    ReadDrafts = "read_drafts",
    ReadVersion = "read_version",
    ReadTemplate = "read_template",
    ReadTemplateList = "read_templatelist",
    UpdateActivities = "update_activities",
    UpdateCategories = "update_categories",
    UpdateWorkpackagesControlinfo = "update_workpackages-controlinfo",
    UpdateWorkpackages = "update_workpackages",
    UpdateWorkpackagesCommissioner = "update_workpackages-commissioner",
    UpdateWorkpackagesMetaInfo = "update_workpackages-metainfo",
    UpdateWorkpackagesResponsible = "update_workpackages-responsible",
    UpdateWorkpackagesDone = "update_workpackages-done",
    UpdateWorkpackagesDecision = "update_workpackages-decision",
    UpdateWorkpackagesPlannedStart = "update_workpackages-plannedstart",
    UpdateWorkpackagesPlannedFinish = "update_workpackages-plannedfinish",
    UpdateActivitySort = "update_activitysort",
    UpdateActivitySortIfResponsible = "update_activitysort-ifresponsible",
    UpdateTemplate = "update_template",
    RoleResponsible = "role_responsible",
    RoleCommissioner = "role_commissioner",
    RoleUserManager = "role_usermanager",
    RoleProjectManager = "role_projectmanager",
    DownloadXlsx = "download_xlsx",
    AdminRestorebackup = "admin_restorebackup",
}
export enum EapContext {
    Code = "<Code>",
    ActivityId = "<ActivityId>",
    Version = "<Version>",
    TemplateName = "<TemplateName>",
}
export enum EapAtoms {
    CategoryInfo = "CategoryInfo",
    CategoryLatest = "CategoryLatest",
    WorkpackageInfo = "WorkpackageInfo",
    WorkpackageLatest = "WorkpackageLatest",
    ProjectTree = "ProjectTree",
    ProjectTreeSnapshot = "ProjectTreeSnapshot",
    VersionList = "VersionList",
    Draft = "Draft",
    ProjectUsers = "ProjectUsers",
    PersonalUserData = "PersonalUserData",
    ProjectActivity = "ProjectActivity",
    TemplateInfo = "TemplateInfo",
    TemplateLatest = "TemplateLatest",
    TemplateList = "TemplateList",
}
export interface IProjectTree {
    entries: IProjectTreeElement[];
}
export interface IWorkpackage {
    code: string;

    /**
     * @default false
     */
    frozen: boolean;

    /**
     * @default ""
     */
    label: string;

    /**
     * @default null
     */
    responsibleUserId: string | null;

    /**
     * @default null
     */
    commissionerUserId: string | null;

    /**
     * @default null
     */
    plannedStart: Date | null;

    /**
     * @default null
     */
    plannedFinish: Date | null;

    /**
     * @default ""
     */
    description: string;

    /**
     * @default ""
     */
    objective: string;

    /**
     * @default ""
     */
    goals: string;

    /**
     * @default null
     */
    dependentWPs: string[] | null;

    /**
     * @default ""
     */
    status: string;

    /**
     * @default Signal.GREEN
     */
    signal: Signal;

    /**
     * @default 0
     */
    progress: number;

    /**
     * @default ""
     */
    progressReason: string;

    /**
     * @default false
     */
    decisionRequired: boolean;

    /**
     * @default ""
     */
    decisionRequiredReason: string;

    /**
     * @default ""
     */
    decision: string;

    /**
     * @default false
     */
    done: boolean;

    /**
     * @default null
     */
    expectedFinish: Date | null;

    /**
     * @default null
     */
    activities: Record<string, IActivity> | null;

    /**
     * @default false
     */
    deleted: boolean;
}
export type ICategoryLog = VersionedAtomData<ICategory>;
export type ListAtomDataType<ValueType> = { entries: Array<ValueType> };
export interface ChangeLog<T = unknown> {
    changes: TypedOperation<T>[];
    author: UserPublicID;
    date: Date;
}
/**
 * this is a trick to get type safety
 * it will give an error string is not assignable to "user_public_id_placeholder" when trying to assing any string
 * it can still be used as index for dictionaries expecting a string as input
 */
export type UserPublicID = "user_public_id_placeholder" | "user_public_id_placeholder1";

export interface IActivity {
    /**
     * @default crypto.randomUUID()
     */
    uuid: string;

    /**
     * @default ""
     */
    description: string;

    /**
     * @default ""
     */
    status: string;

    /**
     * @default null
     */
    plannedFinish: Date | null;

    /**
     * @default null
     */
    expectedFinish: Date | null;

    /**
     * @default null
     */
    currentFinish: Date | null;

    /**
     * @default ""
     */
    label: string;

    /**
     * @default null
     */
    sortIndex: number | null;

    /**
     * @default false
     */
    deleted: boolean;
}
export interface ICategory {
    code: string;
    label: string;

    /**
     * @default ""
     */
    description: string;
    deleted: boolean;
}
export type IVersionList = ListAtomDataType<IVersion>;
export interface IVersion {
    label: string;
    date: Date;
    author: UserPublicID;
    description: string;
}
export type VersionedAtomData<T extends AtomData = AtomData> = {
    __history: Array<ChangeLog<T>>;
};
export type AtomData = { [key: string]: any };
export type TypedOperation<IType> = Operation & {
    path: `/${Paths<IType>}`;
};
export type Paths<T, D extends number = 10> = [D] extends [never]
    ? never
    : T extends object
    ? {
          [K in keyof T]-?: K extends string | number ? `${K}` | Join<K, Paths<T[K], Prev[D]>> : never;
      }[keyof T]
    : "";
type Join<K, P> = K extends string | number
    ? P extends string | number
        ? `${K}${"" extends P ? "" : "/"}${P}`
        : never
    : never;
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, ...0[]];
export type IWorkpackageLog = VersionedAtomData<IWorkpackage>;

export const DraftDeleteItemType = {
    DeleteCategory: "DeleteCategory",
    DeleteWorkpackage: "DeleteWorkpackage",
} as const;
export const DraftAddItemType = {
    AddCategory: "AddCategory",
    AddWorkpackage: "AddWorkpackage",
} as const;
export const DraftMoveItemType = {
    MoveCategory: "MoveCategory",
    MoveWorkpackage: "MoveWorkpackage",
} as const;
export const DraftUpdateItemType = {
    UpdateCategoryTitle: "UpdateCategoryTitle",
    UpdateWorkpackageTitle: "UpdateWorkpackageTitle",
} as const;
export const DraftCategoryItemType = {
    AddCategory: DraftAddItemType.AddCategory,
    DeleteCategory: DraftDeleteItemType.DeleteCategory,
    MoveCategory: DraftMoveItemType.MoveCategory,
    UpdateCategoryTitle: DraftUpdateItemType.UpdateCategoryTitle,
} as const;
export const DraftWorkpackageItemType = {
    AddWorkpackage: DraftAddItemType.AddWorkpackage,
    DeleteWorkpackage: DraftDeleteItemType.DeleteWorkpackage,
    MoveWorkpackage: DraftMoveItemType.MoveWorkpackage,
    UpdateWorkpackageTitle: DraftUpdateItemType.UpdateWorkpackageTitle,
} as const;
export const DraftItemType = {
    ...DraftDeleteItemType,
    ...DraftAddItemType,
    ...DraftMoveItemType,
    ...DraftUpdateItemType,
} as const;
export type DraftItemType = typeof DraftItemType[keyof typeof DraftItemType];
export type IDraftAtom = ListAtomDataType<IDraft>;
export interface IDraft {
    type: DraftItemType;
    code: string;
    label?: string;
    to?: string;
    description?: string;
}
export type IProjectUsers = ListAtomDataType<IProjectUser>;
export interface IProjectUser {
    userId: UserPublicID;
    name: string;
    email: string;
    permissions: string[];
}
export interface IPrivateUserData {
    email: string | null;
    lastSignInAt: Date | null;
    displayName: string | null;
    permissions: string[];
    // firebaseUserData: IFirebaseUserData | null;
}

export const createWorkpackageDraft = (code: string): IWorkpackage => ({
    code: code,
    frozen: false,
    label: "",
    responsibleUserId: null,
    commissionerUserId: null,
    plannedStart: null,
    plannedFinish: null,
    description: "",
    objective: "",
    goals: "",
    dependentWPs: null,
    status: "",
    signal: Signal.GREEN,
    progress: 0,
    progressReason: "",
    decisionRequired: false,
    decisionRequiredReason: "",
    decision: "",
    done: false,
    expectedFinish: null,
    activities: null,
    deleted: false,
});
export const createActivityDraft = (): IActivity => ({
    uuid: crypto.randomUUID(),
    description: "",
    status: "",
    plannedFinish: null,
    expectedFinish: null,
    currentFinish: null,
    label: "",
    sortIndex: null,
    deleted: false,
});

export type IWorkpackageCompact = Omit<IWorkpackage, "activities"> & {
    allActivities: number;
    finishedActivities: number;
    combinedProgress: number;
    lastActivityFinishedAt: Date | null;
} & VersionedMetaInfo;
export interface VersionedMetaInfo {
    deleted: boolean;
    author: UserPublicID;
    date: Date;
}
export type IProjectActivityLog = ListAtomDataType<IProjectActivity> & {
    writeIndex: number;
};
export interface IProjectActivity {
    code: string;
    author: UserPublicID;
    date: Date;
    type: ActivityType;
}
export enum ActivityType {
    CreateWorkpackage = "CreateWorkpackage",
    UpdateWorkpackage = "UpdateWorkpackage",
    DeleteWorkpackage = "DeleteWorkpackage",
    UndeleteWorkpackage = "UndeleteWorkpackage",
    FreezeWorkpackage = "FreezeWorkpackage",
    UnfreezeWorkpackage = "UnfreezeWorkpackage",
    CreateCategory = "CreateCategory",
    UpdateCategory = "UpdateCategory",
    DeleteCategory = "DeleteCategory",
    UndeleteCategory = "UndeleteCategory",
}
export interface ITemplateInfo {
    name: string;
    templateData: JSON;
    deleted: boolean;
    draft: boolean;
}
export type ITemplates = ListAtomDataType<ITemplateInfo>;
export type ITemplateList = ListAtomDataType<ITemplateListElement>;
export type ITemplateLog = VersionedAtomData<ITemplateInfo>;
type ITemplateListElement = Pick<ITemplateInfo, "name" | "draft">;
export type IProjectTreeElement = {
    elementType: ProjectTreeElementType;
} & (ICompactCategory | IWorkpackageCompact);
export type ICompactCategory = {
    code: string;
    label?: string;
} & VersionedMetaInfo;
export enum ProjectTreeElementType {
    Category = "category",
    Workpackage = "workpackage",
}
