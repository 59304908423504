import { faClockRotateLeft, faDiagramProject, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Header as AntHeader } from "antd/es/layout/layout";
import Menu, { MenuProps } from "antd/es/menu/menu";
import Tag from "antd/es/tag";
import { useLayoutEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ENV, getEnvironment } from "../util/getEnvironment";
import "./header.scss";
import { UserButton } from "./UserButton";

interface HeaderProps {
    leftChildren?: React.ReactNode;
    rightChildren?: React.ReactNode;
}
export const Header = ({ leftChildren, rightChildren }: HeaderProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const environment = getEnvironment();
    const [current, setCurrent] = useState(location.pathname);

    useLayoutEffect(() => {
        setCurrent(location.pathname);
    }, [location.pathname]);

    const onClick: MenuProps["onClick"] = ({ key }) => {
        if (key === "link") {
            return;
        }

        navigate(key);
    };

    const itemsLeft: MenuProps["items"] = [
        {
            label: "Home",
            key: "/",
        },
        {
            label: "WBS",
            key: "/wbs/main",
            icon: <FontAwesomeIcon icon={faDiagramProject} />,
            onTitleClick: () => navigate("/wbs"),
            children: [
                {
                    label: "Baum",
                    key: "/wbs",
                },
                {
                    label: "Dokumentation",
                    key: "/wbs/table",
                },
            ],
        },
        {
            label: "Aktivität",
            key: "/activity",
            icon: <FontAwesomeIcon icon={faClockRotateLeft} />,
        },
    ];

    const itemsRight: MenuProps["items"] = [
        {
            label: (
                <a href="https://eap.flexopus.com/" target="_blank" rel="noopener noreferrer">
                    Flexopus
                </a>
            ),
            key: "link",
            icon: <FontAwesomeIcon icon={faExternalLinkAlt} />,
        },
    ];

    return (
        <AntHeader className="header">
            <div style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
                <Link to="/" style={{ display: "flex", alignItems: "center", position: "relative" }}>
                    {environment !== ENV.prd ? (
                        <Tag
                            color={environment === ENV.dev ? "green" : "orange"}
                            style={{
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translateX(-50%) translateY(-50%)",
                            }}
                        >
                            <b>{environment.toUpperCase()}</b>
                        </Tag>
                    ) : null}
                    <img src="/logo.png" alt="Home" />
                </Link>
                <Menu
                    mode="horizontal"
                    onClick={onClick}
                    selectedKeys={[current]}
                    theme="dark"
                    items={itemsLeft}
                    className="w-full pl-7"
                />
                <Menu
                    mode="horizontal"
                    onClick={onClick}
                    selectedKeys={[current]}
                    theme="dark"
                    items={itemsRight}
                    className="pr-7"
                />
            </div>
            {rightChildren}
            <UserButton />
        </AntHeader>
    );
};
