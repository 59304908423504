import { ApolloClient, InMemoryCache } from "@apollo/client";
import { faRotate } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { notification, Spin } from "antd";
import Button from "antd/lib/button";
import type Keycloak from "keycloak-js";
import { makeAuthLink } from "./apollo/makeAuthLink";
import { makeErrorLink } from "./apollo/makeErrorLink";
import { makeHttpLink } from "./apollo/makeHttpLink";
import { makeRehydrateDatesLink } from "./apollo/makeRehydrateDatesLink";
import { makeRetryLink } from "./apollo/makeRetryLink";
import { makeSplitLink } from "./apollo/makeSplitLink";
import { makeSubscriptionsLink } from "./apollo/makeSubscriptionsLink";
import { getBackendUrl } from "./service_urls";
import { getAuthToken } from "./util/keycloak";

export const initApolloClient = (keycloak: Keycloak) => {
    const { graphQlUrl, graphQlSubscriptionUrl } = getBackendUrl();

    return new ApolloClient({
        queryDeduplication: true,
        // defaultOptions: {
        //     mutate: {
        //         errorPolicy: "all",
        //     },
        //     query: {
        //         errorPolicy: "all",
        //     },
        // },
        cache: new InMemoryCache(),
        link: makeSplitLink(
            [makeRetryLink(), makeRehydrateDatesLink(), makeAuthLink(getAuthToken), makeHttpLink(graphQlUrl)],
            [
                makeErrorLink(),
                makeRehydrateDatesLink(),
                makeSubscriptionsLink(graphQlSubscriptionUrl, getAuthToken, 10, 5_000, {
                    onDelayedConnection: () => {
                        notification.info({
                            placement: "top",
                            message: "Verbindungsaufbau",
                            description: "Verbinde zum Server...",
                            key: "ws-connection-delayed",
                            duration: 0,
                            icon: <Spin />,
                        });
                    },
                    connected: () => {
                        notification.destroy("ws-connection-delayed");
                    },
                    onFatalError: () => {
                        notification.destroy("ws-connection-delayed");
                        notification.error({
                            placement: "top",
                            message: "Verbindung fehlgeschlagen",
                            description: (
                                <>
                                    {"Verbindung zum Server fehlgeschlagen"}
                                    <br />
                                    <Button
                                        icon={<FontAwesomeIcon icon={faRotate} />}
                                        className="mt-2"
                                        onClick={() => location.reload()}
                                        type="primary"
                                    >
                                        Erneut versuchen
                                    </Button>
                                </>
                            ),
                            key: "ws-connection-failed",
                            duration: 0,
                        });
                    },
                }),
            ],
        ),
    });
};
