import { Reducer } from "@feinarbyte/reduxhelper";
import { DraftItemType } from "../generated/types";
import { DisplaySettings } from "../types/WBSTreeTypes";

export const defaultDisplaySettings: DisplaySettings = {
    padding: 10,
    spacingBetweenHorizontalChildren: 20,
    spacingBetweenVerticalChildren: 20,
    indentForVerticalChildren: 20,
    indentForHorizontalChildren: 20,
    fontSize: 14,
    showCode: true,
    showDiffOnly: false,
    codeVertical: false,
    boxColor: "#ffffff",
    textColor: "#000000",
    expertMode: false,
};

export interface Carret {
    code: string;
    edit?: boolean;
    editWhat?: "code" | "label";
}

export interface WbsState {
    scale: number;
    collapsedCodes: string[];
    flippedCodes: string[];
    edit?: Carret;
    draft: {
        parent: string;
        type: DraftItemType;
    } | null;
    displaySettings: DisplaySettings;
    showComparedTrees: boolean;
}

export const defaultWbsState: WbsState = {
    scale: 1,
    collapsedCodes: [],
    flippedCodes: [],
    draft: null,
    displaySettings: defaultDisplaySettings,
    showComparedTrees: false,
};

class WbsReducer extends Reducer<WbsState> {
    constructor() {
        super(defaultWbsState, true);
    }

    public resetDisplaySettings() {
        this.state.displaySettings = defaultDisplaySettings;
    }

    public setEdit(code: string, editWhat: "code" | "label") {
        this.state.edit = { code, edit: true, editWhat };
    }

    public stopEdit() {
        if (this.state.edit) {
            this.state.edit!.edit = false;
        }
    }

    public moveCarret(code: string) {
        this.state.edit = { code };
    }
}

const WbsReducerInstance = new WbsReducer();
export const WbsApi = WbsReducerInstance.getApi();
export const WbsReduxReducer = WbsReducerInstance.getReduxReducer();
